/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var pageLang = $('html').attr('lang');
        Cookies.remove('pll_language');

        if( pageLang === 'pt-PT'){
          Cookies.set('pll_language', 'pt');
        } else {
          Cookies.set('pll_language', 'en');
        }

        console.log('language set');


        function startAnim(destination){
          $('body').addClass('travelling');
          $('.animated').animate({
            width: '100%'
          }, 500, function(){
            window.location.href = destination;
          });
        }

        $(document).on('click',"a:not(#compare-button, .file-download)",function(e){
            var clickDest = $(this).attr('href');
            var dload = $(this).attr('download');
            
            if(clickDest && dload !== true){
              localStorage.setItem('travelling','true');
              e.preventDefault();
              startAnim(clickDest); 
            }          
        });

        $(document).on('click','.scrolltop-button',function(){
          $('html , body').animate({
              scrollTop: 0
            }, 1000 )
        })

        $(window).scroll(function(){
          if ($(window).scrollTop() > $(window).height()){
            $('#scroll-container').addClass('scrolled');
          } else {
            $('#scroll-container').removeClass('scrolled');
          }
        })

        // JavaScript to be fired on all pages
        $('#nav-toggle').click(function(){
          $('.nav-extra').slideToggle(500);
          $('body').toggleClass('nav-open');
        });
        $('#language-toggle').click(function(){
          $('.lang-item').slideToggle(300);
          $('ul#lang').toggleClass('active');
        });

        $('.user-toggle').click(function(){
          $(this).closest('ul').toggleClass('active');
          $('ul.user-menu').slideToggle(200);
        });

        
        $('#search-toggle').click(function(){
          $('body').addClass('active-search');
          $('#search-block').addClass('active');
          $('#search-block').removeClass('inactive');
        });
      
        $(document).on('click', '#search-close',function(){
          $('body').removeClass('active-search');
          $('#search-block').removeClass('active');
          $('#search-block').addClass('inactive');
        });

        $('.scroll-link').click(function (){
          var scrollItem = $(this).attr('data-target');
          var targetLocation = $('#'+scrollItem).offset().top - 160;
           $("html, body").animate({
            scrollTop: targetLocation
          }, 500);
        });

        path = window.location.href;

        newPath = path; // path.replace("http://", "//");
        $('nav.nav-primary a').each(function(){ 
          var linkUrl = $(this).attr('href');
          if (linkUrl === newPath){
            $(this).addClass('active');
          }
        });

        if($('body').hasClass('page-template-blog-page') || $('body').hasClass('archive')){
          $('#blog-categories a').each(function(){ 
            var linkUrl = $(this).attr('href');
            if (linkUrl === newPath){
              $(this).addClass('active');
            }
          });
        }

        if($(window).width() < 769){
          $('#mc-embedded-subscribe').appendTo('#btn-container');

          $('#extra-navigation .extra-nav-right').html('');
          $('ul#lang').appendTo('#extra-navigation .extra-nav-right');
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        if($('html').attr('lang') == 'pt-PT'){
          var emailMsg = 'Por favor insira o seu email.';
          var agreeMsg = 'Por favor confirme que concorda com os nossos termos e condições.';
          var emailExists = 'O endereço de email que inseriu já está na nossa base de dados.';
          var emailSuccess = 'Obrigado por subscrever a newsletter da Rodi!';
        } else {
          var emailMsg = 'Please insert your email address.';
          var agreeMsg = 'Please confirm that you agree to our terms and conditions.';
          var emailExists = 'The email address you inserted is already on our database.';
          var emailSuccess = '</h2>Thank you for subscribing to the Rodi newsletter!</h2>';
        } 
      
        var formEl = jQuery('#mc-embedded-subscribe-form');
        
        function register($form) {
          $.ajax({
              type: formEl.attr('method'),
              url: formEl.attr('action'),
              data: formEl.serialize(),
              cache       : false,
              dataType    : 'json',
              contentType: "application/json; charset=utf-8",
              error       : function(err) { alert("Could not connect to the registration server. Please try again later."); },
              success     : function(data) {
                  if (data.result != "success") {
                      // Something went wrong, do something to notify the user. maybe alert(data.msg);
                      console.log(data);
                      $('#mc-result').html(emailExists);
                      $('#mc-result').show();
                  } else {
                      // It worked, carry on...
                      console.log(data);
                      ga('send', {
                        hitType: 'event',
                        eventCategory: 'Registration',
                        eventAction: 'success',
                        eventLabel: 'Website'
                      });
                      $('#mc-result').html(emailSuccess);
                      $('#mc-result').show();
                  }
              }
          });
        }
      
        if($('html').attr('lang') == 'pt-PT'){
          var emailMsg = 'Por favor insira o seu email.';
          var agreeMsg = 'Por favor confirme que concorda com os nossos termos e condições.';
        } else {
          var emailMsg = 'Please insert your email address.';
          var agreeMsg = 'Please confirm that you agree to our terms and conditions.';
        }  
      
        $('#mc-embedded-subscribe-form').validate({
          rules : {
            EMAIL : 'required',
            accept : 'required',
          },
          messages: {
            EMAIL : emailMsg,
            accept : agreeMsg,
          },
          errorPlacement: function(error, element) {
            if ( element.attr("name") == "accept" ){
                error.appendTo("#checkbox-errorbox");
            } else if ( element.attr("name") == "EMAIL" ){
              error.appendTo("#email-error");
            } else {
              error.insertAfter(element);
            };
        }
        })
      
        $(document).on('submit', '#mc-embedded-subscribe-form', function(event) {
          if($('#mc-embedded-subscribe-form').valid()){
            try {
              var $form = $(this);
              event.preventDefault();
              register($form);
            } catch(error){}
          }    
        });
      }
    },
    'page_template_template_login': {
      init: function() {
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var leftSwiper = new Swiper('#banner-left', {
          noSwiping: true,
          speed: 300,
          loop: true,
          effect:'fade',
          fadeEffect: {
            crossFade: false
          },
          autoplay: 'false',
          breakpoints: {
            2000: {
              noSwiping: true,
            },
            768: {
              noSwiping: true,
              init:false,
            },
          },
          init: false
        });

        $('.collection-link').click(function(){
          localStorage.setItem('open_collection', $(this).attr('data-collection'));
        });

        $(window).scroll(function (event) {
          var instagram = $('#instagram');
          var topLim = instagram.offset().top - $(window).height();

          var bottomLim = topLim + instagram.height() + $(window).height();

          var scroll = $(window).scrollTop();

          var movement = (scroll - topLim) / 100 * 3;

          if(scroll > topLim && scroll < bottomLim){
            $('#instagram-content .instagram-before').css('transform', 'translate(-50%, calc( -'+movement+'%))'); 
          }
        });

        var countSwiper = new Swiper('#count-inner', {
          noSwiping: true,
          speed: 50,
          loop: true,
          effect:'none',
          coverflowEffect: {
            rotate: 0,
            slideShadows: false,
            depth:0,
          },
          autoplay: 'false',
          direction: 'vertical',
          breakpoints: {
            2000: {
              noSwiping: true,
            },
            768: {
              noSwiping: true,
              init:false,
            },
          },
          init: false
        });

        var interleaveOffset = 0.5;
        var swiperOptions;
        if($(window).width() > 768 ){
          swiperOptions = {
            loop: true,
            speed: 1000,
            autoplay: {
              delay: 5000,
            },
            grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            direction: 'vertical',
            watchSlidesVisibility: true,
            slidesPerView:1,
            controller: {
              control: [
                leftSwiper,
                countSwiper
              ],
            },
            navigation : {
              nextEl: '#banner-navigation .nav-next',
              prevEl: '#banner-navigation .nav-prev'
            },
            pagination: {
              el: '#banner-pagination',
              type: 'bullets',
              clickable: true
            },
            on: {
              progress: function() {
                if($('.swiper-slide').hasClass('first-slide')){
                  $('.swiper-slide').removeClass('first-slide');
                }
                var swiper = this;
                for (var i = 0; i < swiper.slides.length; i++) {
                  var slideProgress = swiper.slides[i].progress;
                  var innerOffset = swiper.width * interleaveOffset;
                  var innerTranslate = slideProgress * innerOffset;
                  swiper.slides[i].querySelector(".banner-image-wrap").style.transform =
                    "translate3d(0, " + innerTranslate + "px, 60px)";
                }      
              },
              touchStart: function() {
                var swiper = this;
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].style.transition = "";
                }
              },
              setTransition: function(speed) {
                var swiper = this;
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].style.transition = speed + "ms";
                  swiper.slides[i].querySelector(".banner-image-wrap").style.transition =
                    speed + "ms";
                }
              }
            }
          };
        } else {
          swiperOptions = {
            loop: true,
            speed: 1000,
            grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            direction: 'horizontal',
            slidesPerView:1,
            autoplay: {
              delay: 5000,
            },
            controller: {
              control: [
                leftSwiper,
                countSwiper
              ],
            },
            navigation : {
              nextEl: '#banner-navigation .nav-next',
              prevEl: '#banner-navigation .nav-prev'
            },
            pagination: {
              el: '#banner-pagination',
              type: 'bullets',
              clickable: true
            },
            on: {
              progress: function() {
                var swiper = this;
                for (var i = 0; i < swiper.slides.length; i++) {
                  var slideProgress = swiper.slides[i].progress;
                  var innerOffset = swiper.width * interleaveOffset;
                  var innerTranslate = slideProgress * innerOffset;
                  swiper.slides[i].querySelector(".banner-image-wrap").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 60px)";
                }      
              },
              touchStart: function() {
                var swiper = this;
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].style.transition = "";
                }
              },
              setTransition: function(speed) {
                var swiper = this;
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].style.transition = speed + "ms";
                  swiper.slides[i].querySelector(".banner-image-wrap").style.transition =
                    speed + "ms";
                }
              }
            }
          };
        }

        var rightSwiper = new Swiper("#banner-right", swiperOptions);

        leftSwiper.init();
        countSwiper.init();
        rightSwiper.init();

        if($(window).width() < 769){
          $('.news-home').removeClass('col-md-4');
          $('.news-home').addClass('swiper-slide');
          $('#block-news-content').addClass('col-md-12');
          $('#block-news-content').wrapInner('<div class="swiper-container"><div class="swiper-wrapper"></div></div>');
        }  

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        if($(window).width() < 769){
          var relatedSwiper = new Swiper('#block-news-content .swiper-container', {
            noSwiping: false,
            speed: 300,
            loop: false,
            effect:'none',
            autoplay: 'false',
            direction: 'horizontal',
            breakpoints: {
              768: {
                noSwiping: false,
                slidesPerView : 2,
                spaceBetween : 16,
              },
              550: {
                noSwiping: false,
                slidesPerView : 1,
              },
            },
            navigation: {
              prevEl: '#news .related-navigation .nav-prev',
              nextEl: '#news .related-navigation .nav-next',
            },
          });
         
        }
        //Load the Instagram Posts on the Homepage
        var itemCount = 6;
        

        $.ajax({
          type: "GET", 
          url: "https://api.instagram.com/v1/users/self/media/recent/?access_token=8336047806.1677ed0.04b5e1d4eef84d57bc8cfaee775688a6&count="+itemCount,
          crossDomain: true,
          success: function(response) {
              //console.log(response);
      
              $.each(response.data, function(index, obj) {
                 // console.log(obj.images.low_resolution.url);
                    $('#instagram-content > .row').append('<div class="col-md-4 instagram-post"><a href="' + obj.link + '" target="_blank"><div class="img-container" style="background-image:url(' + obj.images.low_resolution.url + ');"></div></a></div>');
              });
          },
          dataType: "jsonp" //set to JSONP, is a callback
        });
      }
    },
    'single_product': {
      init: function() {
        // JavaScript to be fired on all pages
        if($(window).width() < 769){
          $('.product-card').removeClass('col-md-4');
          $('.product-card').addClass('swiper-slide');
          $('#product-extra-inner').addClass('col-md-12');
          $('#product-extra-inner').wrapInner('<div class="swiper-container"><div class="swiper-wrapper"></div></div>');
        } 

        $('#thumbnails-wrapper').removeClass('swiper-container-vertical');

        var interleaveOffset = 0.5;

        var thumbAmt = $('.post-thumbnail-nav').length;

        var minHeight = thumbAmt * 61;

        $('#thumbnails-wrapper').css('min-height', minHeight+'px');

        var swiperOptions;

        if($(window).width() > 768 ){
          swiperOptions = {
            loop: true,
            speed: 1000,
            grabCursor: true,
            autoHeight: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            spaceBetween: 18,
            thumbs:{
              swiper: {
                el: '#thumbnails-wrapper',
                direction: 'vertical',
                slidesPerView: thumbAmt,
              }
            },
            direction: 'vertical',
            on: {
              touchStart: function() {
                var swiper = this;
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].style.transition = "";
                }
              },
              setTransition: function(speed) {
                var swiper = this;
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].style.transition = speed + "ms";
                  swiper.slides[i].querySelector(".product-slide-image").style.transition =
                    speed + "ms";
                }
              }
            }
          };
        } else {
          swiperOptions = {
            loop: true,
            speed: 1000,
            grabCursor: true,
            autoHeight: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            thumbs:{
              swiper: {
                el: '#thumbnails-wrapper',
                direction: 'horizontal',
                slidesPerView: thumbAmt,
              }
            },
            direction: 'horizontal',
          };
        }

        var imageSwiper = new Swiper("#details-image .swiper-container", swiperOptions);

        function activeCutout(){
          var activeCutout = $('a.cutout-toggle.active').html();
          $('#cutout-toggle-placeholder').html(activeCutout);
        }

        if($(window).width() < 769 ){
          activeCutout();

          $('#cutout-toggle-placeholder').on('click',function(){
            $('li.cutout-size-item').slideToggle(200);
          });

          $('a.cutout-toggle').on('click',function(){
            $('#cutout-toggle-placeholder').html($(this).html());
            $('li.cutout-size-item').slideToggle(200);
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var swiperWidth = $('#product-gallery-wrap .section-header').width();

        var relatedSwiper;

        if($(window).width() < 769){
          relatedSwiper = new Swiper('#product-extra-inner .swiper-container', {
            noSwiping: false,
            speed: 300,
            slidesPerView : 1,
            loop: true,
            effect:'none',
            autoplay: 'false',
            direction: 'horizontal',
            breakpoints: {
              768: {
                noSwiping: false,
              },
            },
            navigation: {
              prevEl: '#extras .related-navigation .nav-prev',
              nextEl: '#extras .related-navigation .nav-next',
            },
          });
        }

        $('#product-gallery').width(swiperWidth);

        var gallerySwiper;

        if($('#product-gallery').length > 0 ){

          gallerySwiper = new Swiper('#product-gallery', {
            noSwiping: false,
            speed: 300,
            init:false,
            width:swiperWidth,
            centeredSlides: true,
            loop: false,
            effect:'none',
            autoplay: 'false',
            direction: 'horizontal',
            breakpoints: {
              2000: {
                spaceBetween: 32,
                noSwiping: false,
                slidesPerView: 3,
                initialSlide: 1,
              },
              768: {
                spaceBetween: 16,
                noSwiping: false,
                slidesPerView: 1,
                initialSlide: 0,
              },
            },
            navigation: {
              prevEl: '.gallery-navigation .nav-prev',
              nextEl: '.gallery-navigation .nav-next',
            },
          });

        }



        relatedSwiper = new Swiper('#related-swiper', {
          noSwiping: false,
          speed: 300,
          loop: false,
          effect:'none',
          autoplay: 'false',
          direction: 'horizontal',
          breakpoints: {
            2000: {
              noSwiping: true,
              slidesPerView : 4,
            },
            768: {
              noSwiping: false,
              slidesPerView : 2,
            },
            600: {
              noSwiping: false,
              slidesPerView : 1,
            }
          },
          navigation: {
            prevEl: '.related-navigation .nav-prev',
            nextEl: '.related-navigation .nav-next',
          },
        });

        relatedSwiper = new Swiper('#extra-swiper', {
          noSwiping: false,
          speed: 300,
          loop: false,
          effect:'none',
          autoplay: 'false',
          direction: 'horizontal',
          breakpoints: {
            2000: {
              noSwiping: true,
              slidesPerView : 3,
            },
            768: {
              noSwiping: false,
              slidesPerView : 1,
            },
          },
          navigation: {
            prevEl: '.extra-navigation .nav-prev',
            nextEl: '.extra-navigation .nav-next',
          },
        });

        //Cutout Sizes Toggle
        $(document).on('click', '.cutout-toggle', function(){
          if(! $(this).hasClass('active')){
            var activeCutout = $(this).attr('data-toggle');
            $('.cutout-toggle').removeClass('active');
            $(this).addClass('active');
            $('.cutout-detail .cutout-size-item').removeClass('active');
            $('.cutout-size-item#'+activeCutout).addClass('active');
          }
        });

        if($('#product-gallery').length > 0 ){
          gallerySwiper.init();
        }

        // Scroll Navigate Home Slides
      var photoSwiper = new Swiper('#photo-slider', {
        init: false,
        speed: 800,
        loop: false,
        navigation: {
          nextEl: '.navigation-inner .nav-next',
          prevEl: '.navigation-inner .nav-prev',
        },
        on: {
          slideChangeTransitionEnd	: function () {
            /* do something */
            var realIndex = photoSwiper.activeIndex + 1;
            $('.itemcount.active').html(realIndex);
          },
        }
      });

      var totalSLides = $('#photo-slider .swiper-slide').length;

      $('span.itemcount.inactive').html(totalSLides);

      $('.gallery-link').click(function(){
        $('#photo-slider-outer').delay(200).show();
        $('body').addClass('active-slider');
        photoSwiper.init();
        var clickTarget = $(this).attr('data-id');
        photoSwiper.slideTo(clickTarget, 0);
      });

      $(document).on('click','#slider-close', function(){
        $('#photo-slider-outer').hide();
        $('body').removeClass('active-slider');
      });
      $(document).on('click','#slider-overlay', function(){
        $('#photo-slider-outer').hide();
        $('body').removeClass('active-slider');
      });

      $('.circle-hover').on('mouseover',function(){
        $(this).addClass('hovering');
      });
    
      $('.circle-hover').on('mouseout',function(){
          $(this).removeClass('hovering');
      });

      }
    },
    'single_post': {
      init: function() {

        var relatedSwiper = new Swiper('#related-swiper', {
          noSwiping: false,
          speed: 300,
          loop: true,
          effect:'none',
          autoplay: 'false',
          direction: 'horizontal',
          breakpoints: {
            2000: {
              noSwiping: true,
              slidesPerView : 3,
            },
            768: {
              noSwiping: false,
              slidesPerView : 1,
            },
          },
          navigation: {
            prevEl: '.related-navigation .nav-prev',
            nextEl: '.related-navigation .nav-next',
          },
        });

      }
    },
    // Sinks Page
    'can_filter': {
      init: function() {
        // Primary Ajax Call to get all Products
        var callUrl = $('#postcall').val();
        var productType = $('#product_type').val();

        function viewMore(){
          $(".filter-me").slice(0, 6).addClass('unhide');  
          if ($('.filter-me').length <= 6 ){
              $("#more-wrap").hide();
          } else {
            $("#more-wrap").show();
          }
        }

        

        function firstProductLoad(){
          //Initial Product Load
          $.ajax({
            url: callUrl,
            data: "action=myfilter&post_type=product&product_type="+productType, // form data
            type:'POST', // POST
            beforeSend:function(xhr){
            //filter.find('button').text('Processing...'); // changing the button label
            },
            success:function(data){
              //filter.find('button').text('Apply filter'); // changing the button label back
              $('#response').html(data); // insert data
            },
            error : function(error){ 
              console.log(error);
            },
            
          }).done(function(){
            viewMore();
          });
        }

        

        function getActiveFilters(){

          var pageLang = $('html').attr('lang');
          Cookies.remove('pll_language');

          //console.log(pageLang);

          if( pageLang === 'pt-PT'){
            Cookies.set('pll_language', 'pt');
          } else {
            Cookies.set('pll_language', 'en');
          }


          var ActiveFilters = '';

          var activeFilterGroup = '';
          
          $('.filter-link.active').each(function(){
            ActiveFilters += '&'+$(this).attr('data-tax')+'='+$(this).attr('data-id'); 
            if(!$(this).hasClass('sink-type-toggle')){
              activeFilterGroup += '<a class="mini-filter" data-tax="'+$(this).attr('data-tax')+'" data-id="'+$(this).attr('data-id')+'">'+$(this).html()+'</a>';
            }
          });
          

          if(activeFilterGroup.length > 0 ){
            $('#selected-filters').show();
            $('#selected-filters .active-filter-group').html(activeFilterGroup);
          } else {
            $('#selected-filters').hide();
            //$('#selected-filters .active-filter-group').html(activeFilterGroup);
          }

          if($('.sort-button.active').length > 0 ){
            var sortType = $('a.sort-button.active').attr('data-sort');
            var sortOrder = $('a.sort-button.active').attr('data-order');
            ActiveFilters += '&orderby='+sortType+'&order='+sortOrder;

          }

          $.ajax({
            url: callUrl,
            data: "action=myfilter&post_type=product&product_type="+productType+ActiveFilters, // form data
            type:'POST', // POST
            beforeSend:function(xhr){
            //filter.find('button').text('Processing...'); // changing the button label
            $('#response').html('<div class="loader"><span id="preload-image" class="circular-loader"></span></div>');
            },
            success:function(data){
              //filter.find('button').text('Apply filter'); // changing the button label back
              $('#response').html(data); // insert data
              var currentProductCount = $('.product-card').length;
              $('.item-count').html(currentProductCount);
              
            },
            error : function(error){ 
              console.log(error);
            }
          }).done(function(){
            viewMore();
            if(localStorage.getItem('open_collection') != null){
              localStorage.removeItem('open_collection');
            }
          });  
                
        }

        if(localStorage.getItem('open_collection') != null){
          activeItem = $('a.filter-link[data-id="'+localStorage.getItem('open_collection')+'"]');
          activeItem.addClass('active');
          activeItem.closest('.sink-collection-item').find('.sub-term-toggle').addClass('open');
          activeItem.closest('ul.sub-term').show();
          getActiveFilters();
        } else {
          firstProductLoad();
        }

        $('.filter-toggle').click(function(){
          var target = $(this).attr('data-target');
          $('.filter-toggle').removeClass('active');
          $(this).addClass('active');
          $('.mobile-filter').hide();
          $(target).show();
          $('body').addClass('filter-active');
        });

        $('.mobile-close-button').click(function(){
          $('.mobile-filter').hide();
          $('.filter-toggle').removeClass('active');
          $('body').removeClass('filter-active');
        });

        if($(window).width() < 769){
          $('.filter-link, .sort-button').click(function(){
            $('.mobile-filter').hide();
            $('.filter-toggle').removeClass('active');
            $('body').removeClass('filter-active');
          });
        }

        // On Filter Change (adding a filter item)
        $('.filter-link').click(function(){
          var clickedTax = $(this).attr('data-tax');
          if($(this).hasClass('active')){
            $(this).removeClass('active');
            $(this).closest('.group-list').find('.filter-all').removeClass('inactive');
          } else {
            $('.filter-link[data-tax="'+clickedTax+'"]').removeClass('active');
            $(this).addClass('active');
            $(this).closest('.group-list').find('.filter-all').addClass('inactive');
          }
          getActiveFilters();
          $('.mini-filter span.term-count').remove();
        });
        
        // On Filter Change (setting filter to "all")
        $('.filter-all').click(function(){
          if($(this).hasClass('inactive')){
            $(this).removeClass('inactive');
          }
         var parentGroup = $(this).closest('ul').attr('id');
         //$('#'+parentGroup+' .filter-link').removeClass('active');
         $(this).closest('ul.group-list').find('.filter-link.active').removeClass('active');
         getActiveFilters();
        });

        // On changing results order
        $('a.sort-button').click(function(){
          $('a.sort-button').removeClass('active');
          $('li#toggle-group').removeClass('expanded');
          $(this).addClass('active');
          var currentSort = $(this).html();
          $('#current-sort').html(currentSort);
          $('#product-sort').slideToggle(200);
          getActiveFilters();
        });

        $('.sub-term-toggle').click(function(){
            if($(this).siblings('ul.sub-term').is(':visible')){
              $(this).removeClass('open');
              $(this).siblings('ul.sub-term').hide(200);
            } else {
              $('ul.sub-term').hide(200);
              $('.sub-term-toggle').removeClass('open');
              $(this).addClass('open');
              $(this).siblings('ul.sub-term').show(200);
            }
        });

        $('.sibling-term-toggle').click(function(){
            if($(this).siblings('.filter-inner-group').is(':visible')){
              $(this).siblings('.filter-inner-group').hide(200);
              $(this).removeClass('open');
            } else {
              $('.filter-inner-group').hide(200);
              $(this).siblings('.filter-inner-group').show(200);
              $('.sibling-term-toggle').removeClass('open');
              $(this).addClass('open');
            }
        });

        $('#sort-toggle').click(function(){
          $(this).closest('li').toggleClass('expanded');
          $('#product-sort').slideToggle(200);
        });

        $("#loadmore").on('click', function (e) {
            e.preventDefault();
            $(".filter-me:hidden").slice(0, 8).addClass('unhide');
            if ($(".filter-me:hidden").length === 0) {
              $("#load").fadeOut('slow');
            } 

            if ($('.filter-me:visible').length >= $('.filter-me').length ){
                $("#more-wrap").hide();
            }
        });
        
        $('a.filter-remove').on('click',function(){
          $('.filter-link').removeClass('active');
          getActiveFilters();
        });

        $(document).on('click','a.mini-filter',function(){
          var thisTax = $(this).attr('data-tax');
          var thisID = $(this).attr('data-id');
          $('.filter-link[data-tax="'+thisTax+'"][data-id="'+thisID+'"]').removeClass('active');
          getActiveFilters();
        });

      },
      finalize: function() {
        //Product Comparison
        
        var checkedAmt;
        function checkAmount(){
          var checkboxes = $('.check-compare');
          checkedAmt = checkboxes.filter(':checked').length;
          console.log(checkedAmt);
        }

        var chooseMore;
        var compareBtn;
        var compareMax;
        

        if($('html').attr('lang') === 'pt-PT'){
          chooseMore = 'Escolha mais itens para comparar';
          compareBtn = 'Comparar';
          compareMax = 'Só pode escolher até 3 items';
        } else {
          chooseMore = 'Choose more items to compare';
          compareBtn = 'Compare';
          compareMax = 'You can only compare up to 3 items';
        }

        checkAmount();

        function calcCheckboxes(){
          checkAmount();

          if(checkedAmt < 1 ){
            $('#compare').hide();
            $('#compare-button').hide();
            $('#compare-button').removeClass('notready');
            $('#compare-button').removeClass('ready');
          } else if(checkedAmt === 1 ){
            $('#compare').css('display','flex');
            $('#compare-button').show();
            $('#compare-button .btn-text').html(chooseMore);
            $('#compare-button').addClass('notready');
            $('#compare-button').removeClass('ready');
          } else if ( checkedAmt <= 3 ){
            $('#compare').css('display','flex');
            $('#compare-button .btn-text').html(compareBtn);
            $('#compare-button').removeClass('notready');
            $('#compare-button').addClass('ready');
          } else {
            $('#compare-button .btn-text').html(compareMax);
            $('#compare-button').addClass('notready');
            $('#compare-button').removeClass('ready');
          }
        }

        $(document).on('change','.check-compare', function(){
          calcCheckboxes();
        });

        $('#compare-button').click(function(e){
          e.preventDefault();
          var destination = $(this).attr('href');
          if( checkedAmt > 1 && checkedAmt < 4  ){
            $('.check-compare:checkbox:checked').each(function(){
                var prodID = $(this).attr('product-id');
                destination += prodID+',';
            });
            var compareURL = destination.slice(0,-1);
            window.location = compareURL;
          }
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_about': {
      init: function() {
        // JavaScript to be fired on the about us page
        var aboutSwiper = new Swiper('#about-inner', {
          noSwiping: true,
          speed: 500,
          slidesPerView : 1,
          loop: false,
          autoplay: 'false',
          direction: 'horizontal',
          breakpoints: {
            768: {
              noSwiping: false,
            },
          }
        });

        var aboutTextSwiper = new Swiper('#slideshow-text', {
          noSwiping: false,
          speed: 500,
          slidesPerView : 1,
          loop: false,
          effect:'fade',
          fadeEffect: {
            crossFade: true
          },
          controller : {
            control: aboutSwiper
          },
          autoplay: 'false',
          direction: 'horizontal',
          breakpoints: {
            768: {
              noSwiping: false,
            },
          },
          navigation: {
            prevEl: '.slideshow-controls .related-navigation .nav-prev',
            nextEl: '.slideshow-controls .related-navigation .nav-next',
          }
        });

        var pagTitles = [];

        $('.timeline-date').each(function(){
          pagTitles.push($(this).html());
        });

        var timelineSwiper = new Swiper('#timeline-slider', {
          noSwiping: false,
          speed: 500,
          slidesPerView : 1,
          loop: false,
          controller : {
            control: aboutSwiper
          },
          autoplay: 'false',
          direction: 'horizontal',
          breakpoints: {
            768: {
              noSwiping: false,
            },
          },
          navigation: {
            prevEl: '#timeline-controls .related-navigation .nav-prev',
            nextEl: '#timeline-controls .related-navigation .nav-next',
          },
          pagination: {
            el: '.timeline-pagination',
            type: 'bullets',
            renderBullet: function (index, className) {
              return '<span class="outer-pag-item  '+className+'"><span class="pagination-item-text">' + pagTitles[index] + '</span><span class="pagination-item-circle"></span></span>';
            }
          },
          on: {
            slideChangeTransitionStart : function(){
              var timelineWidth = 100 / (pagTitles.length + 1) * (timelineSwiper.activeIndex + 1 );
              console.log(timelineWidth);
              $('.pagination-line-inner').animate({
                width : timelineWidth+'%'
              }, 500, function(){});
            }     
          }    
        });

        var initialWidth = 100 / (pagTitles.length + 1) +'%';

        $('.pagination-line-inner').animate({
          width : initialWidth
        }, 500, function(){});

        $('.outer-pag-item').width( initialWidth );

      },
      finalize: function(){
        // JavaScript to be fired on all pages, after page specific JS is fired
        var swiperWidth = $('#about-gallery #measure').width();

        //console.log(swiperWidth);

        $('#product-gallery').width(swiperWidth);

        var gallerySwiper = new Swiper('#product-gallery', {
          noSwiping: false,
          speed: 300,
          init:false,
          spaceBetween: 15,
          width:swiperWidth,
          centeredSlides: true,
          loop: false,
          effect:'none',
          autoplay: 'false',
          direction: 'horizontal',
          breakpoints: {
            2000: {
              noSwiping: false,
              slidesPerView: 3,
              initialSlide: 1,
            },
            768: {
              noSwiping: false,
              slidesPerView: 1,
              initialSlide: 0,
            },
          },
          navigation: {
            prevEl: '.gallery-navigation .nav-prev',
            nextEl: '.gallery-navigation .nav-next',
          },
        });

        gallerySwiper.init();

        // Scroll Navigate Home Slides
        var photoSwiper = new Swiper('#photo-slider', {
          init: false,
          speed: 800,
          loop: false,
          navigation: {
            nextEl: '.navigation-inner .nav-next',
            prevEl: '.navigation-inner .nav-prev',
          },
          on: {
            slideChangeTransitionEnd	: function () {
              /* do something */
              var realIndex = photoSwiper.activeIndex + 1;
              $('.itemcount.active').html(realIndex);
            },
          }
        });

        var totalSLides = $('#photo-slider .swiper-slide').length;

        $('span.itemcount.inactive').html(totalSLides);
        
        $('.gallery-link').click(function(){
          $('#photo-slider-outer').delay(200).show();
          $('body').addClass('active-slider');
          photoSwiper.init();
          var clickTarget = $(this).attr('data-id');
          photoSwiper.slideTo(clickTarget, 0);
        });

        $(document).on('click','#slider-close', function(){
          $('#photo-slider-outer').hide();
          $('body').removeClass('active-slider');
        });
        $(document).on('click','#slider-overlay', function(){
          $('#photo-slider-outer').hide();
          $('body').removeClass('active-slider');
        });

        $('.circle-hover').on('mouseover',function(){
          $(this).addClass('hovering');
        });
      
        $('.circle-hover').on('mouseout',function(){
            $(this).removeClass('hovering');
        });

      }
    },
    'page_template_template_private': {
      init: function() {
        var callUrl = $('#postcall').val();

        //Initial Document Load
        $.ajax({
          url: callUrl,
          data: "action=docprivatefilter&post_type=document", // form data
          type:'POST', // POST
          beforeSend:function(xhr){
          //filter.find('button').text('Processing...'); // changing the button label
          },
          success:function(data){
            //filter.find('button').text('Apply filter'); // changing the button label back
            console.log('request sucess');
            $('#response').html(data); // insert data
          },
          error : function(error){ 
            console.log(error);
          },
          
        }).done(function(){
          console.log('request done');
        });

        $('a.display-type').on('click',function(){
          if(!$(this).hasClass('active')){
            $('a.display-type').removeClass('active');
            $(this).addClass('active');
            var newType = $(this).attr('data-display');
            $('#response').attr('data-display', newType );
          }
        });

        localStorage.setItem('all_name', $('#downloads-toggle-placeholder').html());

        $('#sort-toggle').click(function(){
          $(this).closest('li').toggleClass('expanded');
          $('#product-sort').slideToggle(200);
        });

        if($(window).width() < 769 ){
         //activeMaterial();

          $('#downloads-toggle-placeholder').on('click',function(){
            $('li.filter-selector').slideToggle(200);
          });

          $('a.filter-item-toggle').on('click',function(){
            if($(this).hasClass('active')){
              $('#downloads-toggle-placeholder').html($(this).html());
            } else {
              $('#downloads-toggle-placeholder').html(localStorage.getItem('all_name'));
            }

            $('li.filter-selector').slideToggle(200);
          });
        }
        
      },
      finalize: function() {

        var callUrl = $('#postcall').val();

        var ActiveFilters;

        function getActiveFilters(){

          var pageLang = $('html').attr('lang');
          Cookies.remove('pll_language');

          //console.log(pageLang);

          if( pageLang === 'pt-PT'){
            Cookies.set('pll_language', 'pt');
          } else {
            Cookies.set('pll_language', 'en');
          }

          ActiveFilters = "";
          $('.filter-item-toggle.active').each(function(){
            ActiveFilters += '&'+$(this).attr('data-tax')+'='+$(this).attr('data-id'); 
            
          });

          if($('.sort-button.active').length > 0 ){
            var sortType = $('a.sort-button.active').attr('data-sort');
            var sortOrder = $('a.sort-button.active').attr('data-order');
            ActiveFilters += '&orderby='+sortType+'&order='+sortOrder;

          }

          console.log(ActiveFilters);

          $.ajax({
            url: callUrl,
            data: "action=docprivatefilter&post_type=document"+ActiveFilters, // form data
            type:'POST', // POST
            beforeSend:function(xhr){
            //filter.find('button').text('Processing...'); // changing the button label
            $('#response').html('<div class="loader"><span id="preload-image" class="circular-loader"></span></div>');
            },
            success:function(data){
              //filter.find('button').text('Apply filter'); // changing the button label back
              $('#response').html(data); // insert data
              var currentProductCount = $('.document-item').length;
              $('.item-count').html(currentProductCount);
              
            },
            error : function(error){ 
              console.log(error);
            }
          }).done(function(){
          });       
        }

        // On Filter Change (adding a filter item)
        $('.filter-item-toggle').click(function(){
          if($(this).hasClass('active')){
            $(this).removeClass('active');
          } else {
            $('.filter-item-toggle').removeClass('active');
            $(this).addClass('active');
          }
          getActiveFilters();
        });

        // On changing results order
        $('a.sort-button').click(function(){
          $('a.sort-button').removeClass('active');
          $('li#toggle-group').removeClass('expanded');
          $(this).addClass('active');
          var currentSort = $(this).html();
          $('#current-sort').html(currentSort);
          $('#product-sort').slideToggle(200);
          getActiveFilters();
        });

        getActiveFilters();

      }
    },
    'page_template_template_compare': {
      init: function() {
        if($(window).width() < 769 && $(window).width() > 499 ){
          $('.compare-column').each(function(){
            $(this).removeClass('col-md-4');
            $(this).addClass('col-md-6');
          });
        }
        if($(window).width() < 769 ){
          var relProdSwiper = new Swiper('#compare-slider', {
            speed: 300,
            loop: false,
            slidesPerView: 2,
            navigation: {
              nextEl: '.compare-navigation .nav-next',
              prevEl: '.compare-navigation .nav-prev',
            },
          });
        }
        
        var compareQuery = location.search;

        $('li.lang-item a').each(function(){
          var currentURL = $(this).attr('href');
          $(this).attr('href', currentURL+compareQuery);
        });

      }
    },
    'page_template_template_downloads': {
      init: function() {
        var callUrl = $('#postcall').val();

        //Initial Document Load
        $.ajax({
          url: callUrl,
          data: "action=docfilter&post_type=document", // form data
          type:'POST', // POST
          beforeSend:function(xhr){
          //filter.find('button').text('Processing...'); // changing the button label
          },
          success:function(data){
            //filter.find('button').text('Apply filter'); // changing the button label back
            console.log('request sucess');
            $('#response').html(data); // insert data
          },
          error : function(error){ 
            console.log(error);
          },
          
        }).done(function(){
          console.log('request done');
        });

        $('a.display-type').on('click',function(){
          if(!$(this).hasClass('active')){
            $('a.display-type').removeClass('active');
            $(this).addClass('active');
            var newType = $(this).attr('data-display');
            $('#response').attr('data-display', newType );
          }
        });

        localStorage.setItem('all_name', $('#downloads-toggle-placeholder').html());

        $('#sort-toggle').click(function(){
          $(this).closest('li').toggleClass('expanded');
          $('#product-sort').slideToggle(200);
        });

        if($(window).width() < 769 ){
         //activeMaterial();

          $('#downloads-toggle-placeholder').on('click',function(){
            $('li.filter-selector').slideToggle(200);
          });

          $('a.filter-item-toggle').on('click',function(){
            if($(this).hasClass('active')){
              $('#downloads-toggle-placeholder').html($(this).html());
            } else {
              $('#downloads-toggle-placeholder').html(localStorage.getItem('all_name'));
            }

            $('li.filter-selector').slideToggle(200);
          });
        }
        
      },
      finalize: function() {

        var callUrl = $('#postcall').val();

        function getActiveFilters(){

          var pageLang = $('html').attr('lang');
          Cookies.remove('pll_language');

          //console.log(pageLang);

          if( pageLang === 'pt-PT'){
            Cookies.set('pll_language', 'pt');
          } else {
            Cookies.set('pll_language', 'en');
          }
          
          ActiveFilters = "";
          $('.filter-item-toggle.active').each(function(){
            ActiveFilters += '&'+$(this).attr('data-tax')+'='+$(this).attr('data-id'); 
            
          });

          if($('.sort-button.active').length > 0 ){
            var sortType = $('a.sort-button.active').attr('data-sort');
            var sortOrder = $('a.sort-button.active').attr('data-order');
            ActiveFilters += '&orderby='+sortType+'&order='+sortOrder;

          }

          $.ajax({
            url: callUrl,
            data: "action=docfilter&post_type=document"+ActiveFilters, // form data
            type:'POST', // POST
            beforeSend:function(xhr){
            //filter.find('button').text('Processing...'); // changing the button label
            $('#response').html('<div class="loader"><span id="preload-image" class="circular-loader"></span></div>');
            },
            success:function(data){
              //filter.find('button').text('Apply filter'); // changing the button label back
              $('#response').html(data); // insert data
              var currentProductCount = $('.document-item').length;
              $('.item-count').html(currentProductCount);
              
            },
            error : function(error){ 
              console.log(error);
            }
          }).done(function(){
          });       
        }

        // On Filter Change (adding a filter item)
        $('.filter-item-toggle').click(function(){
          if($(this).hasClass('active')){
            $(this).removeClass('active');
          } else {
            $('.filter-item-toggle').removeClass('active');
            $(this).addClass('active');
          }
          getActiveFilters();
        });

        // On changing results order
        $('a.sort-button').click(function(){
          $('a.sort-button').removeClass('active');
          $('li#toggle-group').removeClass('expanded');
          $(this).addClass('active');
          var currentSort = $(this).html();
          $('#current-sort').html(currentSort);
          $('#product-sort').slideToggle(200);
          getActiveFilters();
        });

        getActiveFilters();

      }
    },
    'page_template_template_solutions': {
      init: function() {

        $('.solution-item a').click(function (){
          var scrollItem = $(this).attr('data-target');
          var targetLocation = $('#'+scrollItem).offset().top;
          $(this).addClass('active');
           $("html, body").animate({
            scrollTop: targetLocation
          }, 500);
        });

        var navScroll = $('#solutions-navigation').offset().top;

        $(window).scroll(function (event) {
          var singleNavHeight = navScroll;
          var scroll = $(window).scrollTop();
        
            if(scroll > singleNavHeight){
              $('body').addClass('nav-scrolled');
            } else {
              $('body').removeClass('nav-scrolled');
            }
        });

        function activeMaterial(){
          var activeMaterial = $('a.material-toggle-button.active').html();
          $('#material-toggle-placeholder').html(activeMaterial);
        }

        if($(window).width() < 769 ){
          activeMaterial();

          $('#material-toggle-placeholder').on('click',function(){
            $('li.material-toggle').slideToggle(200);
          });

          $('a.material-toggle-button').on('click',function(){
            $('#material-toggle-placeholder').html($(this).html());
            $('li.material-toggle').slideToggle(200);
          });
        }

        var targets = Array('technology_innovation','materials_finishing','installation_types','sink_configuration','cleaning_maintenance');

        $(window).scroll(function (event) {
          var singleNavHeight = $('#solutions-navigation').outerHeight();
          var scroll = $(window).scrollTop() + singleNavHeight;
          
          $(targets).each(function(i,n){
            var scrolledElement = ('#'+n);
            var scrBef = $(scrolledElement).offset().top - 88;
            var scrAft = scrBef + $(scrolledElement).outerHeight();
        
            
            if(scroll > scrBef && scroll < scrAft ){
              $('a[data-target="'+n+'"]').addClass('active');
            } else {
              $('a[data-target="'+n+'"]').removeClass('active');
            }
        
          }); 
        });

        $('.accordion-item.active').on('click', function(e){
          e.preventDefault();
        });

        var technologySwiper = new Swiper('#technology-swiper', {
          noSwiping: false,
          speed: 500,
          slidesPerView: 1,
          centeredSlides: true,
          loop: false,
          effect:'fade',
          fadeEffect: {
            crossFade: true
          },
          autoplay: {
            delay: 12000,
            disableOnInteraction: false
          },
          direction: 'horizontal',
          breakpoints: {
            768: {
              noSwiping: false,
            },
          },
          pagination: {
            el: '#tech-banner-pagination',
            type: 'bullets',
            clickable: true
          },
          on: {
            init: function(){
              $('#technology_innovation .accordion-item:first-child').addClass('active');
            },
            slideChangeTransitionEnd	: function () {
              /* do something */
              $('#technology_innovation .accordion-item').removeClass('active');
              $('#technology_innovation .accordion-item[data-index="'+technologySwiper.realIndex+'"]').addClass('active');
            },
          }
        });

        $('#technology_innovation .accordion-item').click(function(){
          $('#technology_innovation .accordion-item').removeClass('active');
          $(this).addClass('active');
          var tecSlideIndex = $(this).attr('data-index');

          technologySwiper.slideTo(tecSlideIndex);
        });


        var installationSwiper = new Swiper('#installation-swiper', {
          noSwiping: false,
          speed: 300,
          slidesPerView: 1,
          centeredSlides: true,
          loop: false,
          effect:'fade',
          fadeEffect: {
            crossFade: true
          },
          autoplay: {
            delay: 12000,
            disableOnInteraction: false
          },
          direction: 'horizontal',
          breakpoints: {
            768: {
              noSwiping: false,
            },
          },
          pagination: {
            el: '#install-banner-pagination',
            type: 'bullets',
            clickable: true
          },
          on: {
            init: function(){
              $('#installation_types .accordion-item:first-child').addClass('active');
            },
            slideChangeTransitionEnd	: function () {
              /* do something */
              $('#installation_types .accordion-item').removeClass('active');
              $('#installation_types .accordion-item[data-index="'+installationSwiper.realIndex+'"]').addClass('active');
            },
          }
        });

        $('#installation_types .accordion-item').click(function(){
          $('#installation_types .accordion-item').removeClass('active');
          $(this).addClass('active');
          var instSlideIndex = $(this).attr('data-index');

          installationSwiper.slideTo(instSlideIndex);
        });

        var cleaningSwiper = new Swiper('#cleaning-swiper', {
          noSwiping: false,
          speed: 300,
          slidesPerView: 1,
          centeredSlides: true,
          loop: false,
          effect:'fade',
          fadeEffect: {
            crossFade: true
          },
          autoplay: {
            delay: 12000,
            disableOnInteraction: false
          },
          direction: 'horizontal',
          breakpoints: {
            768: {
              noSwiping: false,
            },
          },
          pagination: {
            el: '#clean-banner-pagination',
            type: 'bullets',
            clickable: true
          },
          on: {
            init: function(){
              $('#cleaning_maintenance .accordion-item:first-child').addClass('active');
            },
            slideChangeTransitionEnd	: function () {
              /* do something */
              $('#cleaning_maintenance .accordion-item').removeClass('active');
              $('#cleaning_maintenance .accordion-item[data-index="'+cleaningSwiper.realIndex+'"]').addClass('active');
            },
          }
        });

        $('#cleaning_maintenance .accordion-item').click(function(){
          $('#cleaning_maintenance .accordion-item').removeClass('active');
          $(this).addClass('active');
          var cleanSlideIndex = $(this).attr('data-index');

          console.log( cleanSlideIndex);

          cleaningSwiper.slideTo(cleanSlideIndex);
        });

        $('.material-toggle-button').on('click', function(){
          if(!$(this).hasClass('active')){
            $('.material-toggle-button').removeClass('active');
            $(this).addClass('active');

            var activeMat = $(this).attr('data-index');

            $('.material-group').removeClass('active');
            $('.material-group[data-index="'+activeMat+'"]').addClass('active');
          }
        });


      }
    },
    // About us page, note the change from about-us to about_us.
    'product_sink': {
      init: function() {

        localStorage.clear();

        var pdfData = {};

        var siteLogo = $('a.brand > svg');
        var technicalImage = $('#pdf-technical-image').attr('data-src');
        var placeholderImage = $('#pdf-placeholder-image').attr('data-src');
        var productPhoto = $('#pdf-image').attr('data-src');

        var myCanvas = document.getElementById("canvas");
        var ctxt = myCanvas.getContext("2d");

        function emptyCanvas(){

          var c = document.getElementById("canvas");
          var ctxt = c.getContext("2d");
          ctxt.clearRect(0, 0, c.width, c.height);
          ctxt.beginPath();
        }
        
        //Convert SVG to PNG
        function drawInlineSVG(svgElement, svgWidth, svgHeight, ctx, callback){

          $('#canvas').attr('width', svgWidth+'px');
          $('#canvas').attr('height',svgHeight+'px');

          var svgURL = new XMLSerializer().serializeToString(svgElement);
          var img  = new Image();
          img.onload = function(){

            $('#canvas').attr('width', svgWidth+'px');
            $('#canvas').attr('height',svgHeight+'px');

            ctx.drawImage(this, 0, 0, svgWidth, svgHeight);
            callback(this);
          };
          img.src = 'data:image/svg+xml; charset=utf8, '+encodeURIComponent(svgURL);
        }

        //Base 64 encode Images
        function toDataURL(url, callback) {
          var xhr = new XMLHttpRequest();
          xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
              callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', url);
          xhr.responseType = 'blob';
          xhr.send();
        }

        function setStorage(){

          localStorage.clear(); 

          toDataURL(
            productPhoto,
            function(dataUrl) {
                localStorage.setItem('product_photo', dataUrl);
            }
          );
          
           //Store Logo Data
          drawInlineSVG(document.querySelector('a.brand > svg'), siteLogo.width(), siteLogo.height(), ctxt, function(){
            localStorage.setItem('main_logo', canvas.toDataURL());
            emptyCanvas();
          });

          //Storage Item Title
          localStorage.setItem('title', $('#pdf-title').html());
          //Storage Description
          localStorage.setItem('description', $.trim($('#pdf-description').text()));
          //Storage Collection
          localStorage.setItem('collection', $.trim($('#product-collection').text()));
          
          localStorage.setItem('tech_title', $.trim($('#pdf-tech-title').text()));
          localStorage.setItem('tech_subtitle', $.trim($('#pdf-tech-subtitle').text()));
          localStorage.setItem('cutouts', $.trim($('#installations-title').text()));
          
          toDataURL(
            technicalImage,
            function(dataUrl) {
                localStorage.setItem('technical_image', dataUrl);
            }
          );

          var techDetails = [];

          $('.tech-detail').each(function(){
            var techTitle = $(this).attr('title');
            var techDetail = $(this).html(); 
            techDetails.push({'title': techTitle, 'detail': techDetail });
            
          });

          localStorage.setItem('technical_details', JSON.stringify(techDetails));

          toDataURL(
            placeholderImage,
            function(dataUrl) {
                localStorage.setItem('technical_image', dataUrl);
            }
          );
          //Awards

          if($('#award_icon').length > 0 ){

            var awardIcon = $('#award_icon > svg');

            drawInlineSVG(document.querySelector('li#award_icon > svg'), awardIcon.width(), awardIcon.height(), ctxt, function(){
              localStorage.setItem('award_icon', canvas.toDataURL());
              emptyCanvas();

            });
          }

          var awardsItems = [];

          $('.award-img').each(function(){
            var tecImg = $(this).attr('data-src');
            var dataVal = $(this).attr('data-item');
             toDataURL(
                tecImg,
                function(dataUrl) {
                    jQuery(document).data(dataVal.toString() , dataUrl );
                    localStorage.setItem(dataVal, dataUrl);
                }
            );

            awardsItems.push(dataVal);

          });

          localStorage.setItem('awards_index', JSON.stringify(awardsItems));

          localStorage.setItem('materials_title', $('#materials-title').html());

          var materialsItems = [];

          $('.material-image').each(function(){
            var tecImg = $(this).attr('data-src');
            var dataVal = $(this).attr('data-index');
            var materialName = $(this).attr('title');
             toDataURL(
                tecImg,
                function(dataUrl) {
                    jQuery(document).data(dataVal.toString() , dataUrl );
                    localStorage.setItem(dataVal, dataUrl);
                }
            );

            materialsItems.push({'material_index' : dataVal, 'material_title' : materialName });

          });

          localStorage.setItem('materials_index', JSON.stringify(materialsItems));

          //Cutout Sizes
          //Cutout Sizes Title
          localStorage.setItem('install_title', $('#installations-title').html());

          //Cutout Sizes Items
          var cutoutItems = [];

          $('.cutout-item').each(function(){
            var tecIcon = $(this).attr('data-icon');
            var imgName = $(this).attr('data-imagename');
            var iconName = $(this).attr('data-iconname');
            var instName = $(this).attr('data-title');

            toDataURL(
              tecIcon,
              function(dataUrlIcn) {
                  jQuery(document).data(iconName.toString() , dataUrlIcn );
                  localStorage.setItem(iconName, dataUrlIcn);
              }
            );

          cutoutItems.push({'cutout_icon': iconName, 'cutout_image': imgName, 'cutout_name': instName});

          });

          $('.cutout-item').each(function(){
            var tecImg = $(this).attr('data-image');
            var imgName = $(this).attr('data-imagename');

            toDataURL(
              tecImg,
              function(dataUrl) {
                  jQuery(document).data(imgName.toString() , dataUrl );
                  localStorage.setItem(imgName, dataUrl);
              }
            );

          });

          localStorage.setItem('cutout_index', JSON.stringify(cutoutItems));

        }

        setStorage();
       
      },
      finalize: function() {
      }
    },
    'product_tap': {
      init: function() {

        localStorage.clear();

        var pdfData = {};

        var siteLogo = $('a.brand > svg');
        var technicalImage = $('#pdf-technical-image').attr('data-src');
        var placeholderImage = $('#pdf-placeholder-image').attr('data-src');
        var productPhoto = $('#pdf-image').attr('data-src');


        var myCanvas = document.getElementById("canvas");
        var ctxt = myCanvas.getContext("2d");

        function emptyCanvas(){

          var c = document.getElementById("canvas");
          var ctxt = c.getContext("2d");
          ctxt.clearRect(0, 0, c.width, c.height);
          ctxt.beginPath();
        
        }


        //Convert SVG to PNG
        function drawInlineSVG(svgElement, svgWidth, svgHeight, ctx, callback){

          $('#canvas').attr('width', svgWidth+'px');
          $('#canvas').attr('height',svgHeight+'px');

          var svgURL = new XMLSerializer().serializeToString(svgElement);
          var img  = new Image();
          img.onload = function(){

            $('#canvas').attr('width', svgWidth+'px');
            $('#canvas').attr('height',svgHeight+'px');

            ctx.drawImage(this, 0, 0, svgWidth, svgHeight);
            callback(this);
          };
          img.src = 'data:image/svg+xml; charset=utf8, '+encodeURIComponent(svgURL);
        }

        //Base 64 encode Images
        function toDataURL(url, callback) {
          var xhr = new XMLHttpRequest();
          xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
              callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', url);
          xhr.responseType = 'blob';
          xhr.send();
        }

        function setStorage(){

          localStorage.clear(); 

          toDataURL(
            productPhoto,
            function(dataUrl) {
                localStorage.setItem('product_photo', dataUrl);
            }
          );
          
           //Store Logo Data
          drawInlineSVG(document.querySelector('a.brand > svg'), siteLogo.width(), siteLogo.height(), ctxt, function(){
            localStorage.setItem('main_logo', canvas.toDataURL());
            emptyCanvas();
          });

          //Storage Item Title
          localStorage.setItem('title', $('#pdf-title').html());
          //Storage Description
          localStorage.setItem('description', $.trim($('#pdf-description').text()));
          //Storage Collection
          localStorage.setItem('collection', $.trim($('#product-collection').text()));
          
          localStorage.setItem('tech_title', $.trim($('#pdf-tech-title').text()));
          localStorage.setItem('tech_subtitle', $.trim($('#pdf-tech-subtitle').text()));
          localStorage.setItem('cutouts', $.trim($('#installations-title').text()));
          
          toDataURL(
            technicalImage,
            function(dataUrl) {
                localStorage.setItem('technical_image', dataUrl);
            }
          );

          var techDetails = [];

          $('.tech-detail').each(function(){
            var techTitle = $(this).attr('title');
            var techDetail = $(this).html(); 
            techDetails.push({'title': techTitle, 'detail': techDetail });
            
          });

          localStorage.setItem('technical_details', JSON.stringify(techDetails));

          toDataURL(
            placeholderImage,
            function(dataUrl) {
                localStorage.setItem('technical_image', dataUrl);
            }
          );
          

          //Awards

          if($('#award_icon').length > 0 ){

            var awardIcon = $('#award_icon > svg');

            drawInlineSVG(document.querySelector('li#award_icon > svg'), awardIcon.width(), awardIcon.height(), ctxt, function(){
              localStorage.setItem('award_icon', canvas.toDataURL());
              emptyCanvas();

            });
          }

          var awardsItems = [];

          $('.award-img').each(function(){
            var tecImg = $(this).attr('data-src');
            var dataVal = $(this).attr('data-item');
             toDataURL(
                tecImg,
                function(dataUrl) {
                    jQuery(document).data(dataVal.toString() , dataUrl );
                    localStorage.setItem(dataVal, dataUrl);
                }
            );

            awardsItems.push(dataVal);

          });

          localStorage.setItem('awards_index', JSON.stringify(awardsItems));

          localStorage.setItem('materials_title', $('#materials-title').html());

          var materialsItems = [];

          $('.material-image').each(function(){
            var tecImg = $(this).attr('data-src');
            var dataVal = $(this).attr('data-index');
            var materialName = $(this).attr('title');
             toDataURL(
                tecImg,
                function(dataUrl) {
                    jQuery(document).data(dataVal.toString() , dataUrl );
                    localStorage.setItem(dataVal, dataUrl);
                }
            );

            materialsItems.push({'material_index' : dataVal, 'material_title' : materialName });

          });

          localStorage.setItem('materials_index', JSON.stringify(materialsItems));

          //Cutout Sizes
          //Cutout Sizes Title
          localStorage.setItem('install_title', $('#installations-title').html());

          //Cutout Sizes Items
          var cutoutItems = [];

          $('.cutout-item').each(function(){
            var tecIcon = $(this).attr('data-icon');
            var imgName = $(this).attr('data-imagename');
            var iconName = $(this).attr('data-iconname');
            var instName = $(this).attr('data-title');

            toDataURL(
              tecIcon,
              function(dataUrlIcn) {
                  jQuery(document).data(iconName.toString() , dataUrlIcn );
                  localStorage.setItem(iconName, dataUrlIcn);
              }
            );

          cutoutItems.push({'cutout_icon': iconName, 'cutout_image': imgName, 'cutout_name': instName});

          });

          $('.cutout-item').each(function(){
            var tecImg = $(this).attr('data-image');
            var imgName = $(this).attr('data-imagename');

            toDataURL(
              tecImg,
              function(dataUrl) {
                  jQuery(document).data(imgName.toString() , dataUrl );
                  localStorage.setItem(imgName, dataUrl);
              }
            );

          });

          localStorage.setItem('cutout_index', JSON.stringify(cutoutItems));

        }

        setStorage();
       
      },
      finalize: function() {
      }
    },
    'product_accessory': {
      init: function() {

        var pdfData = {};

        var siteLogo = $('a.brand > svg');
        //var technicalImage = $('#pdf-technical-image').attr('data-src');
        var placeholderImage = $('#pdf-placeholder-image').attr('data-src');
        var productPhoto = $('#pdf-image').attr('data-src');


        var myCanvas = document.getElementById("canvas");
        var ctxt = myCanvas.getContext("2d");

        function emptyCanvas(){

          var c = document.getElementById("canvas");
          var ctxt = c.getContext("2d");
          ctxt.clearRect(0, 0, c.width, c.height);
          ctxt.beginPath();
        
        }


        //Convert SVG to PNG
        function drawInlineSVG(svgElement, svgWidth, svgHeight, ctx, callback){

          $('#canvas').attr('width', svgWidth+'px');
          $('#canvas').attr('height',svgHeight+'px');

          var svgURL = new XMLSerializer().serializeToString(svgElement);
          var img  = new Image();
          img.onload = function(){

            $('#canvas').attr('width', svgWidth+'px');
            $('#canvas').attr('height',svgHeight+'px');

            ctx.drawImage(this, 0, 0, svgWidth, svgHeight);
            callback(this);
          };
          img.src = 'data:image/svg+xml; charset=utf8, '+encodeURIComponent(svgURL);
        }

        //Base 64 encode Images
        function toDataURL(url, callback) {
          var xhr = new XMLHttpRequest();
          xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
              callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', url);
          xhr.responseType = 'blob';
          xhr.send();
        }

        function setStorage(){

          localStorage.clear(); 

          toDataURL(
            productPhoto,
            function(dataUrl) {
                localStorage.setItem('product_photo', dataUrl);
            }
          );
          
           //Store Logo Data
          drawInlineSVG(document.querySelector('a.brand > svg'), siteLogo.width(), siteLogo.height(), ctxt, function(){
            localStorage.setItem('main_logo', canvas.toDataURL());
            emptyCanvas();
          });

          //Storage Item Title
          localStorage.setItem('title', $('#pdf-title').html());
          //Storage Description
          localStorage.setItem('description', $.trim($('#pdf-description').text()));
          //Storage Collection
          localStorage.setItem('collection', $.trim($('#product-collection').text()));
          
          localStorage.setItem('tech_title', $.trim($('#pdf-tech-title').text()));
          localStorage.setItem('tech_subtitle', $.trim($('#pdf-tech-subtitle').text()));
          localStorage.setItem('cutouts', $.trim($('#installations-title').text()));

          var techDetails = [];

          $('.tech-detail').each(function(){
            var techTitle = $(this).attr('title');
            var techDetail = $(this).html(); 
            techDetails.push({'title': techTitle, 'detail': techDetail });
            
          });

          localStorage.setItem('technical_details', JSON.stringify(techDetails));

          toDataURL(
            placeholderImage,
            function(dataUrl) {
                localStorage.setItem('technical_image', dataUrl);
            }
          );
          

          //Awards

          if($('#award_icon').length > 0 ){

            var awardIcon = $('#award_icon > svg');

            drawInlineSVG(document.querySelector('li#award_icon > svg'), awardIcon.width(), awardIcon.height(), ctxt, function(){
              localStorage.setItem('award_icon', canvas.toDataURL());
              emptyCanvas();

            });
          }

          var awardsItems = [];

          $('.award-img').each(function(){
            var tecImg = $(this).attr('data-src');
            var dataVal = $(this).attr('data-item');
             toDataURL(
                tecImg,
                function(dataUrl) {
                    jQuery(document).data(dataVal.toString() , dataUrl );
                    localStorage.setItem(dataVal, dataUrl);
                }
            );
            awardsItems.push(dataVal);

          });

          localStorage.setItem('awards_index', JSON.stringify(awardsItems));

          localStorage.setItem('materials_title', $('#materials-title').html());

          var materialsItems = [];

          $('.material-image').each(function(){
            var tecImg = $(this).attr('data-src');
            var dataVal = $(this).attr('data-index');
            var materialName = $(this).attr('title');
             toDataURL(
                tecImg,
                function(dataUrl) {
                    jQuery(document).data(dataVal.toString() , dataUrl );
                    localStorage.setItem(dataVal, dataUrl);
                }
            );

            materialsItems.push({'material_index' : dataVal, 'material_title' : materialName });

          });

          localStorage.setItem('materials_index', JSON.stringify(materialsItems));

          //Cutout Sizes
          //Cutout Sizes Title
          localStorage.setItem('install_title', $('#installations-title').html());

          //Cutout Sizes Items
          var cutoutItems = [];

          $('.cutout-item').each(function(){
            var tecIcon = $(this).attr('data-icon');
            var imgName = $(this).attr('data-imagename');
            var iconName = $(this).attr('data-iconname');
            var instName = $(this).attr('data-title');

            toDataURL(
              tecIcon,
              function(dataUrlIcn) {
                  jQuery(document).data(iconName.toString() , dataUrlIcn );
                  localStorage.setItem(iconName, dataUrlIcn);
              }
            );

          cutoutItems.push({'cutout_icon': iconName, 'cutout_image': imgName, 'cutout_name': instName});

          });

          $('.cutout-item').each(function(){
            var tecImg = $(this).attr('data-image');
            var imgName = $(this).attr('data-imagename');

            toDataURL(
              tecImg,
              function(dataUrl) {
                  jQuery(document).data(imgName.toString() , dataUrl );
                  localStorage.setItem(imgName, dataUrl);
              }
            );

          });

          localStorage.setItem('cutout_index', JSON.stringify(cutoutItems));

        }

        setStorage();
       
      },
      finalize: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
